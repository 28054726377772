import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 py-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "text-muted fw-bold fs-7" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2" }
const _hoisted_8 = { class: "card-body p-0 d-flex flex-column" }
const _hoisted_9 = { class: "card-p pt-5 bg-white flex-grow-1" }
const _hoisted_10 = { class: "row g-0" }
const _hoisted_11 = { class: "col-md-3 mr-8" }
const _hoisted_12 = { class: "fs-7 text-muted fw-bold" }
const _hoisted_13 = { class: "d-flex align-items-center" }
const _hoisted_14 = { class: "fs-4 fw-bolder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.text), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(item.count), 1)
              ])
            ]))
          }), 256))
        ])
      ])
    ])
  ], 2))
}
<template>
  <div>
    <div class="row gy-5 g-xl-8 mb-4">
      <div class="col-md-3"></div>
      <div class="col-md-6 mt-2">
        <el-date-picker
            v-model="filters.dates"
            type="daterange"
            range-separator="-"
            start-placeholder="Filter By Date"
            value-format="YYYY-MM-DD"
            :shortcuts="shortcuts"
        />
      </div>
      <div class="col-md-3"></div>
    </div>
    <div v-loading="loading"></div>
    <div >
      <div class="row gy-5 g-xl-8">
        <div class="col-xxl-4">
          <statistics-card :title="salesSummaryItems.title" :text="salesSummaryItems.text" :sub-text="salesSummaryItems.sub_text" :items="salesSummaryItems.items"
                           widget-classes="card-xl-stretch mb-xl-8" widget-color="primary"></statistics-card>
        </div>
        <div class="col-xxl-4">
          <statistics-card :title="orderSummaryItems.title" :text="orderSummaryItems.text" :sub-text="orderSummaryItems.sub_text" :items="orderSummaryItems.items"
                           widget-classes="card-xl-stretch mb-xl-8" widget-color="success"></statistics-card>
        </div>
        <div class="col-xxl-4">
          <statistics-card :title="customerItems.title" :text="customerItems.text" :sub-text="customerItems.sub_text" :items="customerItems.items"
                           widget-classes="card-xl-stretch mb-xl-8" widget-color="danger"></statistics-card>
        </div>

      </div>
      <div class="row gy-5 g-xl-8">
        <div class="col-xxl-12">
          <card :title="orderStatusesCount.title" :text="orderStatusesCount.description" :items="orderStatusesCount.order_statuses" widget-classes="card-xl-stretch mb-xl-8"></card>
        </div>

      </div>
      <div class="row gy-5 g-xl-8">
        <div class="col-xxl-6">
          <chart-bar-card v-if="ordersStatistics.months" :title="ordersStatistics.title" :categories="ordersStatistics.months" :series="ordersStatistics.series" color="--bs-primary" widget-classes="card-xl-stretch mb-xl-8"></chart-bar-card>
        </div>
        <div class="col-xxl-6">
          <chart-bar-card v-if="usersStatistics.months" :title="usersStatistics.title" :categories="usersStatistics.months" :series="usersStatistics.series" color="--bs-success" widget-classes="card-xl-stretch mb-xl-8"></chart-bar-card>
        </div>
      </div>
      <div class="row gy-5 g-xl-8">
        <div class="col-xxl-6">
          <chart-bar-card v-if="providersStatistics.months" :title="providersStatistics.title" :categories="providersStatistics.months" :series="providersStatistics.series" color="--bs-warning" widget-classes="card-xl-stretch mb-xl-8"></chart-bar-card>
        </div>
        <div class="col-xxl-6">
          <chart-area-card v-if="orderPricesStatistics.months" :colors="orderPricesStatistics.colors" :title="orderPricesStatistics.title" :categories="orderPricesStatistics.months" :series="orderPricesStatistics.series"  widget-classes="card-xl-stretch mb-xl-8"></chart-area-card>
        </div>

      </div>
      <div class="row gy-5 g-xl-8">
        <div class="col-md-4">
          <div class="card" >
            <!--begin::Header-->
            <div class="card-header border-0">
              <h3 class="card-title fw-bolder text-dark">New Users</h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-2">
              <template v-for="(item, index) in latestUsers" :key="index">
                <!--begin::Item-->
                <div :class="{ 'mb-7': latestUsers.length - 1 !== index }" class="d-flex align-items-center">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-50px me-5">
                    <img :src="item.image" class="" alt="" />
                  </div>
                  <!--end::Avatar-->

                  <!--begin::Text-->
                  <div class="flex-grow-1">
                    <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{item.first_name+" "+item.last_name }}</a>

                    <span class="text-muted d-block fw-bold">{{item.phone }}</span>
                  </div>
                  <!--end::Text-->
                </div>
                <!--end::Item-->
              </template>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="col-md-4">
          <div class="card" >
            <!--begin::Header-->
            <div class="card-header border-0">
              <h3 class="card-title fw-bolder text-dark">New Providers</h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-2">
              <template v-for="(item, index) in latestProviders" :key="index">
                <!--begin::Item-->
                <div :class="{ 'mb-7': latestProviders.length - 1 !== index }" class="d-flex align-items-center">
                  <!--begin::Avatar-->
                  <div class="symbol symbol-50px me-5">
                    <img :src="item.image" class="" alt="" />
                  </div>
                  <!--end::Avatar-->

                  <!--begin::Text-->
                  <div class="flex-grow-1">
                    <a href="#" class="text-dark fw-bolder text-hover-primary fs-6">{{
                        item.first_name+" "+item.last_name
                      }}</a>

                    <span class="text-muted d-block fw-bold">{{item.phone }}</span>
                  </div>
                  <!--end::Text-->
                </div>
                <!--end::Item-->
              </template>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <div class="col-md-4">
          <div class="card" >
            <!--begin::Header-->
            <div class="card-header border-0">
              <h3 class="card-title fw-bolder text-dark">New Orders</h3>
            </div>
            <!--end::Header-->

            <!--begin::Body-->
            <div class="card-body pt-2">
              <template v-for="(item, index) in latestOrders" :key="index">
                <!--begin::Item-->
                <div
                    :class="{ 'mb-8': latestOrders.length - 1 !== index }"
                    class="d-flex align-items-center"
                >
                  <!--begin::Description-->
                  <div class="flex-grow-1">
                    <a href="#" class="text-gray-800 text-hover-primary fw-bolder fs-6">{{ item.service }}</a>
                    <span class="text-muted fw-bold d-block">Order # : {{ item.id }}</span>
                  </div>
                  <!--end::Description-->

                  <span class="badge fs-8 fw-bolder" :class="`badge-light-primary`">{{ item.status }}</span>
                </div>
                <!--end:Item-->
              </template>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script >
import {defineComponent, onMounted , ref , watch} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import StatisticsCard from "@/components/admin/dashboard/StatisticsCard.vue";
import ChartBarCard from "@/components/admin/dashboard/ChartBarCard.vue";
import ChartAreaCard from "@/components/admin/dashboard/ChartAreaCard.vue";
import Card from "@/components/admin/dashboard/Card.vue";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {APIs} from "@/store/enums/ApiEnums";
import {handleError} from "@/core/helpers/response";

export default defineComponent({
  name: "dashboard",
  components: {
    Card,
    ChartAreaCard,
    ChartBarCard,
    StatisticsCard,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
    const filters = ref({dates : []});
    const shortcuts = [
      {
        text: 'Last week',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: 'Last month',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: 'Last 3 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
      {
        text: 'Last 6 months',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
          return [start, end]
        },
      },

    ]
    const loading = ref(false);

    const salesSummaryItems = ref([]);
    const orderSummaryItems = ref([]);
    const customerItems = ref([]);
    const orderStatusesCount = ref([]);
    const ordersStatistics = ref({series : [] , categories : []});
    const usersStatistics = ref({series : [] , categories : []});
    const providersStatistics = ref({series : [] , categories : []});
    const orderPricesStatistics = ref({series : [] , categories : []});

    const latestUsers = ref([]);
    const latestProviders = ref([]);
    const latestOrders = ref([]);

    const list = ref([
      {
        avatar: "media/avatars/300-6.jpg",
        name: "Emma Smith",
        description: "Project Manager",
      },
      {
        avatar: "media/avatars/300-5.jpg",
        name: "Sean Bean",
        description: "PHP, SQLite, Artisan CLI",
      },
      {
        avatar: "media/avatars/300-11.jpg",
        name: "Brian Cox",
        description: "PHP, SQLite, Artisan CLI",
      },
      {
        avatar: "media/avatars/300-9.jpg",
        name: "Francis Mitcham",
        description: "PHP, SQLite, Artisan CLI",
      },
      {
        avatar: "media/avatars/300-23.jpg",
        name: "Dan Wilson",
        description: "PHP, SQLite, Artisan CLI",
      },
    ]);
    const fetchData = async ()  => {
      try {
        loading.value = true;
        let response = await ApiAxiosService.get(APIs.DASHBOARD.dashboard , filters.value);
        salesSummaryItems.value = response.data.data.sales_summary;
        orderSummaryItems.value = response.data.data.order_number;
        customerItems.value = response.data.data.customers_number;
        orderStatusesCount.value = response.data.data.order_statuses_count;
        ordersStatistics.value = response.data.data.orders_statistics;
        usersStatistics.value = response.data.data.users_statistics;
        providersStatistics.value = response.data.data.provider_statistics;
        orderPricesStatistics.value = response.data.data.order_prices_statistics;
        latestUsers.value = response.data.data.latest_users;
        latestProviders.value = response.data.data.latest_providers;
        latestOrders.value = response.data.data.latest_orders;
        loading.value = false;
      } catch (e) {
        handleError(e)
      }
    }
    fetchData();

    watch(filters.value, (newValue) => {
      fetchData();
    });
    return {
      loading,
      filters,
      shortcuts,
      salesSummaryItems,
      orderSummaryItems,
      customerItems,
      orderStatusesCount,
      ordersStatistics,
      usersStatistics,
      orderPricesStatistics,
      providersStatistics,

      latestUsers,
      latestProviders,
      latestOrders,
    }
  },
});
</script>



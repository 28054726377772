import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_apexchart, {
        type: "area",
        options: _ctx.options,
        series: _ctx.series
      }, null, 8, ["options", "series"])
    ])
  ], 2))
}
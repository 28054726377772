
import { defineComponent, ref } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

export default defineComponent({
  name: "card",
  components: {
    Dropdown1,
  },
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
    items : Array,
    title : String,
    text: String,
  },
  setup(props) {
    const color = ref(props.chartColor);

    return {

    };
  },
});
